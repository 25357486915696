import React from "react";

import vfxDemo from "../../Assets/vfx-demo.mp4";
import animationDemo from "../../Assets/2d-demo.mp4";
import vfxmobile from "../../Assets/vfxmobile.mp4";
import mobile2d from "../../Assets/mobile2d.mp4";
import photovfx from "../../Assets/vfxtmb.png";
import photo2d from "../../Assets/tmb2d.png";
import "./Work.scss";


export default class Work extends React.Component {
  constructor(props) {
    super(props);
    this.vfxVideoRef = React.createRef();
    this.vfxVideoRef2 = React.createRef();
    this.animationVideoRef = React.createRef();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    setTimeout(() => {
      this.vfxVideoRef.current.play();
      this.animationVideoRef.current.play();
    }, 1000);
  }


  handleVideoHover = (videoRef) => {
    videoRef.current.play();
    
  };

  handleVideoLeave = (videoRef) => {
    videoRef.current.pause();
  };

  render() {
    const isMobile = window.innerWidth < 768;
    return (
      <div className="work">    
        <div
          className="vfx-half halfscreen"
          onMouseEnter={() => this.handleVideoHover(this.vfxVideoRef)}
          onMouseLeave={() => this.handleVideoLeave(this.vfxVideoRef)}
          onClick={() => {
            window.location.href = "/work";
            
          }}
        >
          <h1>VFX</h1>
          
          <video ref={this.vfxVideoRef} muted loop>
            <source src={isMobile ? vfxmobile : vfxDemo} type="video/mp4" />
          </video>
          
        </div>

        <div
          className="animation-half halfscreen"
          onMouseEnter={() => this.handleVideoHover(this.animationVideoRef)}
          onMouseLeave={() => this.handleVideoLeave(this.animationVideoRef)}
          onClick={() => {
            
            window.location.href = "/work2d";
            
          }}
        >             
          <h1>2D ANIMATION</h1>
          <video ref={this.animationVideoRef} muted loop>
          <source src={isMobile ? mobile2d : animationDemo} type="video/mp4" />
          </video>
        </div>

        <div className="mobile-workstuff">
       
          <div className="phonevfx">
            <h1>VFX</h1>

             <img src={photovfx} alt="logo" className="phonevfxpic" 
             style={{ width: "100%", height: "100%", borderRadius: "38px" }}
             onClick={() => {        
              window.location.href = "/work";           
            }}/>

          </div>

          <div className="phone2d" >
          <div className="2dphonetext">
          <h1>2D ANIMATION</h1>
          </div>
          
          
           <img src={photo2d} alt="logo" className="phone2dpic"
           style={{ width: "100%", height: "100%", borderRadius: "38px" }} 
           onClick={() => {        
            window.location.href = "/work2d";           
          }}/>

          </div>

        </div>
        

      </div>

    );
  }
}
