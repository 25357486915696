import React from "react";
import "./Header.scss";
import Logo from "../../Assets/logo.png";
import { NavLink, useHistory, useLocation } from "react-router-dom";

export default class Header extends React.Component {
  
  render() {
    return (
      <div className="header">
        <div className="header-elements">   
        <div className="header-items">
          <NavLink to="/" className="nav-link">
            <div className="header-item header-item-1">
              <img src={Logo} alt="logo" className="header-logo"/>
              <div className="header-text">SIDE-EYE</div>
            </div>
          </NavLink>       
          
          <NavLink
            to="/contact"
            className="nav-link"
            activeClassName="active-button"
          >
            <div className="header-item">
              <button className="contact-button">
                <div className="header-text2 ">CONTACT US</div>
              </button>
            </div>
          </NavLink>
          </div>
        </div>
      </div>
    );
  }
}