import "./App.css";
import LandingPage from "./Components/Landing/LandingPage";
import Header from "./Components/Header/Header";
import WorkShowcase from "./Components/WorkShowcase/WorkShowcase";
import WorkShowcase2D from "./Components/WorkShowcase/WorkShowcase2D";
import Contact from "./Components/Contact/Contact";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import logo from "./Assets/logo.png";
function App() {
  return (
    <div className="App">
     
      <Router>
        <Routes >
          <Route path="header" exact element={<Header />} />
          <Route path="*" exact element={<LandingPage />} />
          <Route path="/work" element={<WorkShowcase />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/work2d" element={<WorkShowcase2D />} />
        </Routes>

        <div className="blurry-logo">
          <img src={logo} alt="Logo" />
        </div>
      </Router>
    </div>
  );
}

export default App;
