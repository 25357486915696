import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "./Contact.scss";

export default class Contact extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  

  constructor(props) {
    super(props);
    
    this.state = {
      name: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
      file: null, // Change to null initially
      fileLabel: "Max file size: 10MB",
      hasSubmitted: false,
    };
    
  }

  

  updateFileLabel = (event) => {
    const file = event.target;
    const fileLabel = document.getElementById("fileLabel");

    if (file.files.length > 0) {
      const fileName = file.files[0].name;
      fileLabel.textContent = fileName;
    } else {
      fileLabel.textContent = "Max file size: 10MB";
    }

    // Update the state with the selected file
    this.setState({ file: file.files[0] });
  };

  

  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  render() {
    const { hasSubmitted } = this.state;
    
    if (hasSubmitted) {
      return (
        <div className="d-contact">
          <Header />
        <div className="contact-page">
          <div className="contact-body">
            <div className="contact-header my-5">
              <h1>THANK YOU!</h1>
              <p>Your submission has been received!</p>
            </div>
          </div>
          <Footer />
          
          </div>
         
        </div>
      );
    }

    return (
      
      <div className="d-contact">
      <Header />
      <div className="contact-page">       
       
        <div className="contact-body">
          <div className="contact-header">
            <h1>Contact Us</h1>
            <p>Let's work together!</p>
          </div>
          <form
            action="https://formspree.io/f/xdoqnopg"
            method="POST"
            onSubmit={this.handleSubmit}
            encType="multipart/form-data" // Set the form enctype to handle file uploads
          >
            <div className="form-group">
              <label htmlFor="name">Name*</label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                placeholder="John"
                value={this.state.name}
                onChange={this.handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email*</label>
              <input
                type="text"
                className="form-control"
                id="email"
                name="mail"
                placeholder="contact@gmail.com"
                value={this.state.email}
                onChange={this.handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="phone">Phone Number*</label>
              <input
                type="text"
                className="form-control"
                id="phone"
                name="phone"
                placeholder="+1 (805) 393-2464"
                value={this.state.phone}
                onChange={this.handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="subject">Subject*</label>
              <select
                type="text"
                className="form-control form-control2"
                id="subject"
                name="subject"
                placeholder="Select one"
                value={this.state.subject}
                onChange={this.handleChange}
                required
              >
                <option value="" disabled selected hidden>
                  Select one
                </option>
                <option value="VFX">VFX</option>
                <option value="2D Animation">2D Animation</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="message">Tell us more</label>
              <textarea
                type="text"
                className="form-control"
                id="message"
                name="message"
                value={this.state.message}
                onChange={this.handleChange}
                required
              />
            </div>
            {/* 
            <div className="form-group">
              <label htmlFor="file">Drop in any relevant files here</label>
              <input
                type="file"
                className="form-control mb-4"
                id="file"
                name="file"
                placeholder="Max file size 10MB."
                onChange={this.updateFileLabel}
              />
              <div id="fileLabel">{this.state.fileLabel}</div>
            </div>*/}

            <button type="submit" className="form-button">
              Submit
            </button>
          </form>
        </div>
       
        </div>
        
        <Footer />  
      </div>
    );
  }
}
