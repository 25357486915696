import React from "react";
import "./WorkShowcase.scss";
import Footer from "../Footer/Footer";
import Slider2 from "./Slider/Slider2";
import { NavLink} from "react-router-dom";
import logo from "../../Assets/logo.png";
import photo1 from "../../Assets/photo1.png";
import photo2 from "../../Assets/photo2.png";
import photo3 from "../../Assets/photo3.png";
import photo4 from "../../Assets/photo4.png";
import photo5 from "../../Assets/photo5.png";
import photo6 from "../../Assets/photo6.png";
import photo7 from "../../Assets/photo7.png";
import photo8 from "../../Assets/photo8.png";
import photo9 from "../../Assets/photo9.png";
import photo10 from "../../Assets/photo10.png";
import photo11 from "../../Assets/photo11.png";
import photo12 from "../../Assets/photo12.png";
import Header from "../Header/Header";

export default class WorkShowcase2D extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVFX: true,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  videos = [
    { src: photo8, alt: "Music Video 8", text: "347aidan - IDWK", youtubeLink: "https://www.youtube.com/watch?v=7-aP9PfRWa8" },
    { src: photo9, alt: "Music Video 9", text: "Sleepy Hallow - 2055", youtubeLink: "https://www.youtube.com/watch?v=y1xZ_kAhjMc" },
    { src: photo10, alt: "Music Video 10", text: "Sheff G - Drum Dummy", youtubeLink: "https://www.youtube.com/watch?v=Fwoi4106eRU" },
    { src: photo12, alt: "Music Video 11", text: "Sheff G - Break From It", youtubeLink: "https://www.youtube.com/watch?v=YqhCgEFuHXQ" },
     // Add more objects as needed
  ];

  additionalVideo = {
    src: photo2,
    alt: "Additional Video",
    text: "Trippie Redd’s ALLTY5 Toy Shop Speakeasy ",
    youtubeLink: "https://www.youtube.com/watch?v=zUwsZW93gSY",
  };


  render() {
    return (
      
      <div className="divforfooter">
      <Header /> 
      <div className="work-showcase">
      
        <div className="work-showcase-body">
          <div className="work-showcase-content">
          
            <div className="logoandtitle">

              <div className="logoplace">

                <div className="logotwotext">             
                    SIDE
                </div>

                  <div className="logotwo">
                    <img src={logo} alt="logo" style = {{width: '100%', height: '100%'}} />
                  </div>

                <div className="logotwotext">
                    EYE
                </div>

              </div>
              <div className="title-left">
                <h1 className="pagename" >  
                  2D Animation
               </h1>
               </div>
          </div>
            
            <div className="d-flex">
            
            <NavLink to="/work">
            <button
                    className={
                      "button-inactive"
                      }
                      onClick={() => this.setState({ isVFX: true })}               
                  >
                        <p>VFX</p>
                  </button>
                  </NavLink>  

                  <button
                className={
                  "button-active"
                }
                onClick={() => this.setState({ isVFX: true })}
              >
                <p>2D Animation</p>
              </button>
             
            </div>
            
            <div className="work-videotext">

              <div className="work-video">
              <iframe
                title="Vimeo Video"
                className="video"
                src="https://player.vimeo.com/video/878789873"
                frameborder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
                style={{ width: "100%", height: "100%"}}
                >                 
              </iframe>
              </div>

              <div className="work-text">
                <div className="work-text-title">
                2D REEL

                </div>

                <div className="work-text-body">
                At SIDE-EYE we specialize in visual effects - 3D and 2D animation. Helping you bring your ideas to life.
                </div>
              </div>

            
            </div>
          
          </div>
          <Slider2/>
          <div className="mobile-stuff">
              <div className="mobile-container">      
                <div className="mobile-title">
                  <p>
                  2D ANIMATION
                  </p>
                  </div>


                  <div className="d-flexphone">
            
                  <NavLink to="/work">
                  <button
                    className={
                      "button-inactivephone"
                      }
                      onClick={() => this.setState({ isVFX: true })}               
                  >
                        <p>VFX</p>
                  </button>
                  </NavLink> 

                  <button
                  className={
                  "button-activephone"
                  }
                  onClick={() => this.setState({ isVFX: true })}
                  >
                  <p>2D Animation</p>
                  </button> 
                  
                  
                  </div>
                  <div className="title-text1"> DEMO REEL</div>
                    {/*<div className="title-text1"> VFX REEL</div>*/}
                      <div className="iframedemo">
       
                        <iframe
                        title="Vimeo Video"
                        className="frame"
                        src="https://player.vimeo.com/video/878789873"
                        frameborder="0"
                        allow="autoplay; fullscreen"
                        style={{width: '100%', height: '100%'}}
                        allowFullScreen
                        >                 
                        </iframe>
            
                      </div>

                    
                        <div className="title-text1"> MUSIC VIDEOS</div>

                          <div className="music-videos-row">
                            {this.videos.map((video, index) => (
                              <div className="music-video" key={index}>
                                <a href={video.youtubeLink} target="_blank" rel="noopener noreferrer">
                                  <img src={video.src} alt={video.alt} style={{width: '100%', height: '100%'}} />
                                </a>
                                <div className="text-under-video">{video.text}</div>

                                
                              </div>

                              
                            ))}

                                    <NavLink to="/contact">
                                    <button className="contact-us">
                                      <p>Contact Us</p>
                                    </button>
                                  </NavLink> 
                          </div>

                                
                          
                                 
                            </div>
            </div>
        </div>

       
      </div>

            

        <Footer />
      </div>
    );
  }
}
