import React, { useState } from "react";
import "./Slider.scss";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, EffectCoverflow} from 'swiper/modules';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import 'swiper/css/effect-coverflow';


import logo from "../../../Assets/logo.png";
import demophoto from "../../../Assets/demophoto1.png";
import photo1 from "../../../Assets/photo1.png";
import photo2 from "../../../Assets/photo2.png";
import photo8 from "../../../Assets/photo8.png";
import photo9 from "../../../Assets/photo9.png";
import photo10 from "../../../Assets/photo10.png";
import photo11 from "../../../Assets/photo11.png";
import photo12 from "../../../Assets/photo12.png";


const Slider2 = ({}) => {
  return (
    <div className="container">
      
      <div className="heading">
        <div className="latest-work">
        Latest Work
          </div>
      <div className="buttons-container">

        <div className="buttonprev">
        <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M9.38462 18L2 10L9.38462 2M3.02564 10L18 10"
                stroke="#EFEFE7"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>

          <div className="buttonnext">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M10.6154 2L18 10L10.6154 18M16.9744 10L2 10"
                stroke="#EFEFE7"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>  
      </div>
      
      </div>
      
      <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        slidesPerView={3}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2.5,
        }}
        pagination={{ el: '.swiper-pagination', clickable: true }}
        navigation={{
          nextEl: '.buttonnext',
          prevEl: '.buttonprev',
          clickable: true,
        }}
        modules={[EffectCoverflow, Pagination, Navigation]}
        className="swiper_container"
      >
        <SwiperSlide>
          <a href="https://www.youtube.com/watch?v=7-aP9PfRWa8" target="_blank" rel="noopener noreferrer">
          <img src={photo8} alt="slide_image" />
          <div className="slide-text">347aidan - IDWK</div>     
          </a> 
        </SwiperSlide>
        
        <SwiperSlide>
          <a href="https://www.youtube.com/watch?v=y1xZ_kAhjMc" target="_blank" rel="noopener noreferrer">
          <img src={photo9} alt="slide_image" />
          <div className="slide-text">Sleepy Hallow - 2055</div>     
          </a> 
        </SwiperSlide>


        <SwiperSlide>
          <a href="https://www.youtube.com/watch?v=Fwoi4106eRU" target="_blank" rel="noopener noreferrer">
          <img src={photo10} alt="slide_image" />
          <div className="slide-text">Sheff G - Drum Dummy</div>     
          </a> 
        </SwiperSlide>

        <SwiperSlide>
          <a href="https://www.youtube.com/watch?v=4gxTv2qqO1I" target="_blank" rel="noopener noreferrer">
          <img src={photo11} alt="slide_image"/>
          <div className="slide-text">Sleepy Hallow - Gloomy Conscious</div>     
          </a> 
        </SwiperSlide>

        <SwiperSlide>
          <a href="https://www.youtube.com/watch?v=YqhCgEFuHXQ" target="_blank" rel="noopener noreferrer">
          <img src={photo12} alt="slide_image" />
          <div className="slide-text">Sheff G - Break From It</div>     
          </a> 
        </SwiperSlide>


        
      </Swiper>
    </div>
  );
};

export default Slider2;




