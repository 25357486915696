// make a class that combines the Landing and Work components and exports it

import React from "react";
import Landing from "./Landing";
import Work from "../Work/Work";
import Logo from "../Logo/Logo";
import Footer from "../Footer/Footer";
import LogoImage from "../../Assets/logo.png";
import "./LandingPage.scss";

export default class LandingPage extends React.Component {
  
  render() {
    return (
      <>
        <Logo/>
        <Landing/>
        <div className="mobile-paragraph">  
          <div className="mobile-logo">
            <h1>SIDE</h1>
            <img src={LogoImage} alt="logo" className="logo img-fluid" />
            <h1>EYE</h1>
          </div>
          <p>
          At SIDE-EYE we specialize in visual effects - 3D and 2D animation. Helping you bring your ideas to life.</p>
        </div>
        <h1 className="mobile-title">WORK</h1>
        <Work />
        <div className="landing-footer">
          <Footer />
        </div>
      </>
    );
  }
}
