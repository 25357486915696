import React from "react";
import LogoImage from "../../Assets/logo.png";
import "./Logo.scss";
import newlogo from "../../Assets/newlogo.png";

export default class Logo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fadeInLogo: false,
      shrinkLogo: false, // Add a new state property to track whether the logo should shrink
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ fadeInLogo: true });
    }, 1000);

    window.addEventListener("scroll", this.handleScroll); // Add event listener to listen for scroll events
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll); // Remove event listener when component is unmounted
  }

  handleScroll = () => {
    const scrollPosition = window.scrollY;
    const shouldShrink = scrollPosition >= (window.innerHeight / 2); // Check if scroll position is greater than or equal to half the page height

    this.setState({ shrinkLogo: shouldShrink }); // Update the state to indicate whether the logo should shrink or not
  }


  render() {
    const { fadeInLogo, shrinkLogo } = this.state;
    return (
      <div className="logo-body">
        {/*<h1 className="welcome-text">WELCOME TO</h1>*/}
        <div className={`home-logo ${fadeInLogo ? "fade-in" : ""} ${shrinkLogo ? "shrink-logo" : ""}`}>
          <h1>SIDE</h1>
          <img src={newlogo} alt="logo" className="logo" />
          <h1>EYE</h1>
        </div>
      </div>
      
    );
  }
}
