import React from "react";
import "./Landing.scss";
import vfxDemo from "../../Assets/vfx-demo.mp4";
import Header from "../Header/Header";
import LogoImage from "../../Assets/newlogo.png";
import vfxphone from "../../Assets/vfxphone.png";
import animphone from "../../Assets/animphone.png";
import LandingSlider from "./LandingSlider/LandingSlider";

export default class Landing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fadeInVideo: false,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ fadeInVideo: true });
    }, 3000);
  }

  render() {
    const { fadeInVideo } = this.state;
    const { onScrollToTarget } = this.props;
    return (
      <div className="landing-container">       
        <Header/>

      {/*Mobile stuff*/}

      <div className="landing-mobile-content">
        <div className="phone-logo">
          <div className="phone-logo-text">SIDE</div>
          <img src={LogoImage} alt="logo" className="phone-logo" />
          <div className="phone-logo-text">EYE</div>
          </div>

        <div className="mobile-slider">     
          <LandingSlider/>
        </div>

     
      </div>

      {/*Not-Mobile stuff*/}

      <div className="landing">     
        <div
          //className={`fullscreen-background ${fadeInVideo ? "fade-in" : ""}`}
          className={`fullscreen-background ${fadeInVideo}`}
        >
          <video autoPlay muted loop>
            <source src={vfxDemo} type="video/mp4" />
          </video>
        </div>  
        {/*<div className="welcome-text" style = {{opacity: fadeInVideo ? 1 : 0}}>WELCOME TO SIDE-EYE</div>
         */}

      </div>

      </div>
    );
  }
}