import React from "react";
import LogoImage from "../../Assets/logo.png";
import newlogo from "../../Assets/newlogo.png";
import "./Footer.scss";
import { NavLink } from "react-router-dom";

export default class Footer extends React.Component {
  render() {
    return (
      <div className="footer">
        <div className="footer-items">
        <div className="footer-text"> 
          
          <div className="footer-logo">
            <img src={newlogo} alt="logo" 
            className="logo img-fluid"
            style={{width: '100%', height: '100%'}} />
          </div>

          <div className="ourwork">
            
            <div className="h3">Our Work</div>	
            <NavLink to="/work">
              <div className="p">VFX Work</div>
            </NavLink>
            <NavLink to="/work2d">
            <div className="p">2D Animations</div>
            </NavLink>
          </div>

          <div className="contact">
          <div className="h3">Contact us</div>
          <div className="p">contact@side-eye.com</div>
          <div className="p">+1 (516) 421-0431</div>
          </div>

        </div>

        <div className="footer-links">
          <div className="footer-copyright">
          ©️SIDE-EYE
          </div>

          <div className="footer-social">
          <a href="https://www.instagram.com/sideeye.mov/" target="_blank" rel="noopener noreferrer" 
          className="instaicon"> 
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="34px"
                    height="35px"
                    
                    fill="none"
                  >
                    <g clip-path="url(#clip0_255_104)">
                      <path
                        d="M7.725 -0.25H19.275C23.675 -0.25 27.25 3.325 27.25 7.725V19.275C27.25 21.3901 26.4098 23.4186 24.9142 24.9142C23.4186 26.4098 21.3901 27.25 19.275 27.25H7.725C3.325 27.25 -0.25 23.675 -0.25 19.275V7.725C-0.25 5.6099 0.590221 3.58143 2.08582 2.08582C3.58143 0.590221 5.6099 -0.25 7.725 -0.25ZM7.45 2.5C6.13718 2.5 4.87813 3.02152 3.94982 3.94982C3.02152 4.87813 2.5 6.13718 2.5 7.45V19.55C2.5 22.2862 4.71375 24.5 7.45 24.5H19.55C20.8628 24.5 22.1219 23.9785 23.0502 23.0502C23.9785 22.1219 24.5 20.8628 24.5 19.55V7.45C24.5 4.71375 22.2862 2.5 19.55 2.5H7.45ZM20.7188 4.5625C21.1746 4.5625 21.6118 4.74358 21.9341 5.06591C22.2564 5.38824 22.4375 5.82541 22.4375 6.28125C22.4375 6.73709 22.2564 7.17426 21.9341 7.49659C21.6118 7.81892 21.1746 8 20.7188 8C20.2629 8 19.8257 7.81892 19.5034 7.49659C19.1811 7.17426 19 6.73709 19 6.28125C19 5.82541 19.1811 5.38824 19.5034 5.06591C19.8257 4.74358 20.2629 4.5625 20.7188 4.5625ZM13.5 6.625C15.3234 6.625 17.072 7.34933 18.3614 8.63864C19.6507 9.92795 20.375 11.6766 20.375 13.5C20.375 15.3234 19.6507 17.072 18.3614 18.3614C17.072 19.6507 15.3234 20.375 13.5 20.375C11.6766 20.375 9.92795 19.6507 8.63864 18.3614C7.34933 17.072 6.625 15.3234 6.625 13.5C6.625 11.6766 7.34933 9.92795 8.63864 8.63864C9.92795 7.34933 11.6766 6.625 13.5 6.625ZM13.5 9.375C12.406 9.375 11.3568 9.8096 10.5832 10.5832C9.8096 11.3568 9.375 12.406 9.375 13.5C9.375 14.594 9.8096 15.6432 10.5832 16.4168C11.3568 17.1904 12.406 17.625 13.5 17.625C14.594 17.625 15.6432 17.1904 16.4168 16.4168C17.1904 15.6432 17.625 14.594 17.625 13.5C17.625 12.406 17.1904 11.3568 16.4168 10.5832C15.6432 9.8096 14.594 9.375 13.5 9.375Z"
                        fill="#EFEFE7"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_255_104">
                        <rect width="27" height="27" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </a>
                <a className="vimeoicon" href="https://vimeo.com/893936745" target="_blank" rel="noopener noreferrer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="27px"
                    height="37px"
                    viewBox="0 4 17 24"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_255_106)">
                      <path
                        d="m 15.72,9.431 c -0.069,1.514 -1.127,3.588 -3.172,6.22 -2.114,2.749 -3.903,4.124 -5.367,4.124 -0.906,0 -1.673,-0.837 -2.3,-2.512 C 4.463,15.728 4.044,14.194 3.626,12.659 3.161,10.985 2.662,10.147 2.128,10.147 c -0.116,0 -0.524,0.245 -1.221,0.733 L 0.176,9.937 C 0.943,9.263 1.7,8.588 2.445,7.912 3.469,7.028 4.237,6.562 4.75,6.515 5.96,6.399 6.705,7.227 6.985,8.998 c 0.302,1.912 0.511,3.101 0.628,3.566 0.349,1.586 0.733,2.378 1.152,2.378 0.326,0 0.815,-0.515 1.467,-1.543 0.651,-1.029 1,-1.812 1.047,-2.349 0.093,-0.888 -0.256,-1.333 -1.047,-1.333 -0.373,0 -0.757,0.085 -1.151,0.255 0.764,-2.504 2.224,-3.721 4.38,-3.652 1.598,0.047 2.351,1.084 2.259,3.111"
                        fill="#EFEFE7"
                        
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_255_106">
                        <rect width="37" height="27" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </a>

          </div>

        </div>
        

        </div>

      </div>
    );
  }
}