import React, { useState } from "react";
import "./Slider.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, EffectCoverflow } from "swiper/modules";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import "swiper/css/effect-coverflow";

import logo from "../../../Assets/logo.png";
import demophoto from "../../../Assets/demophoto1.png";
import photo1 from "../../../Assets/photo1.png";
import photo2 from "../../../Assets/photo2.png";
import photo3 from "../../../Assets/photo3.png";
import photo4 from "../../../Assets/photo4.png";
import photo5 from "../../../Assets/photo5.png";
import photo6 from "../../../Assets/photo6.png";
import photo7 from "../../../Assets/photo7.png";
import photo13 from "../../../Assets/photo13.png";

const Slider = ({ videos }) => {
  return (
    <div className="container user-select-none">
      <div className="heading">
        <div className="latest-work">Latest Work</div>
        <div className="buttons-container">
          <div className="buttonprev">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M9.38462 18L2 10L9.38462 2M3.02564 10L18 10"
                stroke="#EFEFE7"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>

          <div className="buttonnext">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M10.6154 2L18 10L10.6154 18M16.9744 10L2 10"
                stroke="#EFEFE7"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>

      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        slidesPerView={3}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2.5,
        }}
        pagination={{ el: ".swiper-pagination", clickable: true }}
        navigation={{
          nextEl: ".buttonnext",
          prevEl: ".buttonprev",
          clickable: true,
        }}
        modules={[EffectCoverflow, Pagination, Navigation]}
        className="swiper_container"
      >
        {videos.map((video, index) => (
          <SwiperSlide key={index}>
            <a
              href={video.youtubeLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={video.src} alt={video.alt} />
              <div>
                <div className="slide-text">
                  {video.text.split("\n").map((line, i) => (
                    <React.Fragment key={i}>
                      <span
                        style={{
                          fontSize: line.length > 25 ? "smaller" : "inherit",
                        }}
                      >
                        {line}
                      </span>
                      <br />
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </a>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Slider;
