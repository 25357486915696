import React from "react";
import "./WorkShowcase.scss";
import Footer from "../Footer/Footer";
import Slider from "./Slider/Slider";
import { NavLink } from "react-router-dom";
import logo from "../../Assets/logo.png";
import photo1 from "../../Assets/photo1.png";
import photo2 from "../../Assets/photo2.png";
import photo3 from "../../Assets/photo3.png";
import photo4 from "../../Assets/photo4.png";
import photo5 from "../../Assets/photo5.png";
import photo6 from "../../Assets/photo6.png";
import photo7 from "../../Assets/photo7.png";
import photo13 from "../../Assets/photo13.png";
import photo15 from "../../Assets/photo15.png";
import photo16 from "../../Assets/photo16.png";
import photo17 from "../../Assets/photo17.png";
import photo18 from "../../Assets/photo18.png";
import photo19 from "../../Assets/photo19.png";
import photo20 from "../../Assets/photo20.png";
import photo21 from "../../Assets/photo21.png";
import photo22 from "../../Assets/photo22.png";
import photo23 from "../../Assets/photo23.png";
import photo24 from "../../Assets/photo24.png";
import photo25 from "../../Assets/photo25.png";

import Header from "../Header/Header";
import newlogo from "../../Assets/newlogo.png";

export default class WorkShowcase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVFX: true,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  videos = [
    {
      src: photo23,
      alt: "Tobey",
      text: "Eminem ft. Big Sean & BabyTron \n Tobey",
      youtubeLink: "https://youtu.be/CanCZktm0TQ",
    },
    {
      src: photo18,
      alt: "THE THIN GREY LINE",
      text: "$UICIDEBOY$ \n THE THIN GREY LINE",
      youtubeLink: "https://www.youtube.com/watch?v=xmyZHot0Lqc",
    },
    {
      src: photo15,
      alt: "Quevedo",
      text: "LA ÚLTIMA \n Quevedo",
      youtubeLink: "https://www.youtube.com/watch?v=AwSFuXm5wxg",
    },
    {
      src: photo25,
      alt: "La Cruz",
      text: "LIA KALI \n La Cruz",
      youtubeLink: "https://youtu.be/T3lh4AaFr8s",
    },
    {
      src: photo16,
      alt: "EXCALIBUR",
      text: "DUCKBOY \n EXCALIBUR",
      youtubeLink: "https://www.youtube.com/watch?v=IP-XtEYWfg4",
    },
    {
      src: photo7,
      alt: "VANISH",
      text: "Dro Kenji \n VANISH",
      youtubeLink: "https://www.youtube.com/watch?v=pHJqjE3y_W0",
    },
    {
      src: photo5,
      alt: "Let’s Lose Our Minds",
      text: "SoFaygo \n Let’s Lose Our Minds",
      youtubeLink: "https://www.youtube.com/watch?v=yG28xiNlG3c",
    },
    {
      src: photo3,
      alt: "COPE",
      text: "Jamario \n COPE",
      youtubeLink: "https://www.youtube.com/watch?v=xjuu2JgmPCY",
    },
    {
      src: photo4,
      alt: "USED2KNOW",
      text: "Dro Kenji \n USED2KNOW",
      youtubeLink: "https://www.youtube.com/watch?v=Ih7DEc82myw",
    },
    {
      src: photo13,
      alt: "Daily",
      text: "Jamario \n Daily",
      youtubeLink: "https://youtu.be/oEKgefVwM4M?si=IhLcGuZbvQQLZ3QZ",
    },
    {
      src: photo2,
      alt: "Trippie Redd’s ALLTY5 Toy Shop Speakeasy",
      text: "Trippie Redd’s ALLTY5 Toy Shop Speakeasy ",
      youtubeLink: "https://www.youtube.com/watch?v=zUwsZW93gSY",
    },
    {
      src: photo17,
      alt: "DOOR TO DUSK",
      text: "Odetari \n DOOR TO DUSK",
      youtubeLink: "https://www.youtube.com/watch?v=LlMExSAFMxg",
    },
    {
      src: photo22,
      alt: "Rolling Loud x STIIIZY #2",
      text: "The Loudest Pack in Town \n Rolling Loud x STIIIZY #2",
      youtubeLink: "https://youtu.be/-hAiTlEcki4",
    },
    {
      src: photo20,
      alt: "Headrush",
      text: "Ski Mask The Slump God \n Headrush",
      youtubeLink: "https://www.youtube.com/watch?v=h11clvpGuNI",
    },
    {
      src: photo21,
      alt: "Rolling Loud x STIIIZY #1",
      text: "The Loudest Pack in Town \n Rolling Loud x STIIIZY #1",
      youtubeLink: "https://www.youtube.com/watch?v=8Um0IcbXIhc",
    },
    {
      src: photo24,
      alt: "Hate Me",
      text: "Lil Yachty & Ian \n Hate Me",
      youtubeLink: "https://youtu.be/FjFPy3N2tV4",
    },
    {
      src: photo19,
      alt: "Shibuya",
      text: "Ski Mask The Slump God \n Shibuya",
      youtubeLink: "https://www.youtube.com/watch?v=t4xfQDvrfqQ",
    },
  ];

  additionalVideo = {
    src: photo2,
    alt: "Additional Video",
    text: "Trippie Redd’s ALLTY5 Toy Shop Speakeasy ",
    youtubeLink: "https://www.youtube.com/watch?v=zUwsZW93gSY",
  };

  render() {
    return (
      <div className="divforfooter">
        <Header />
        <div className="work-showcase">
          <div className="work-showcase-body">
            <div className="work-showcase-content">
              <div className="logoandtitle">
                <div className="logoplace">
                  <div className="logotwotext">SIDE</div>

                  <div className="logotwo">
                    <img
                      src={newlogo}
                      alt="logo"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>

                  <div className="logotwotext">EYE</div>
                </div>
                <div className="title-left">
                  <h1 className="pagename">
                    {this.state.isVFX ? "VFX" : "2D Animation"}
                  </h1>
                </div>
              </div>

              <div className="d-flex">
                <button
                  className={"button-active"}
                  onClick={() => this.setState({ isVFX: true })}
                >
                  <p>VFX</p>
                </button>

                <NavLink to="/work2d">
                  <button
                    className={
                      !this.state.isVFX ? "button-active" : "button-inactive"
                    }
                    onClick={() => this.setState({ isVFX: false })}
                  >
                    <p>2D Animation</p>
                  </button>
                </NavLink>
              </div>

              <div className="work-videotext">
                <div className="work-video">
                  <iframe
                    title="Vimeo Video"
                    className="video"
                    src="https://player.vimeo.com/video/893936745"
                    frameborder="0"
                    allow="autoplay; fullscreen"
                    allowFullScreen
                    style={{ width: "100%", height: "100%" }}
                  ></iframe>
                </div>

                <div className="work-text">
                  <div className="work-text-title">VFX REEL</div>

                  <div className="work-text-body">
                    At SIDE-EYE we specialize in visual effects - 3D and 2D
                    animation. Helping you bring your ideas to life.
                  </div>
                </div>
              </div>
            </div>
            <Slider videos={this.videos} />
            <div className="mobile-stuff">
              <div className="mobile-container">
                <div className="mobile-title">
                  <p>VFX</p>
                </div>

                <div className="d-flexphone">
                  <button
                    className={"button-activephone"}
                    onClick={() => this.setState({ isVFX: true })}
                  >
                    <p>VFX</p>
                  </button>

                  <NavLink to="/work2d">
                    <button
                      className={
                        !this.state.isVFX
                          ? "button-activephone"
                          : "button-inactivephone"
                      }
                      onClick={() => this.setState({ isVFX: false })}
                    >
                      <p>2D Animation</p>
                    </button>
                  </NavLink>
                </div>

                <div className="title-text1"> DEMO REEL</div>
                {/*<div className="title-text1"> VFX REEL</div>*/}
                <div className="iframedemo">
                  <iframe
                    title="Vimeo Video"
                    className="frame"
                    src="https://player.vimeo.com/video/893936745"
                    frameborder="0"
                    allow="autoplay; fullscreen"
                    style={{ width: "100%", height: "100%" }}
                    allowFullScreen
                  ></iframe>
                </div>

                <div className="title-text1"> MUSIC VIDEOS</div>

                <div className="music-videos-row">
                  {this.videos.map((video, index) => (
                    <div className="music-video" key={index}>
                      <a
                        href={video.youtubeLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={video.src}
                          alt={video.alt}
                          style={{ width: "100%", height: "100%" }}
                        />
                      </a>
                      <div className="text-under-video">{video.text}</div>
                    </div>
                  ))}
                </div>

                <div className="title-text2"> COMMERCIALS</div>
                <div className="music-videos-row">
                  <div className="music-video">
                    <a
                      href={this.additionalVideo.youtubeLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={this.additionalVideo.src}
                        alt={this.additionalVideo.alt}
                        style={{ width: "100%", height: "100%" }}
                      />
                    </a>
                    <div className="text-under-video">
                      {this.additionalVideo.text}
                    </div>
                  </div>
                  <NavLink to="/contact">
                    <button className="work-contact-button">
                      <p>Contact Us</p>
                    </button>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}
